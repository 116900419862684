import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    // minHeight: "250px",
    "& .react-swipeable-view-container > div": {},
  },
}));

export default useStyles;
