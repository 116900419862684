import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Input,
  Menu,
  MenuItem,
  Pagination,
  Skeleton,
  Switch,
  Typography,
  createFilterOptions,
  TextField,
} from "@mui/material";
// import snoop from "../assets/snoop.png";
import MenuItems from "../components/menu/Menu";
import NotFound from "../components/notFound/NotFound";
import ButtonIndicator from "../components/buttonIndicator/ButtonIndicator";
import { makeStyles } from "@mui/styles";
import { GTypesItem } from "./constants";

const styles = {
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    padding: "5px 0px",
  },
  address: {
    display: "flex",
    alignItems: "flex-start",
    padding: "5px 0px",
    // borderBottom: "1px solid black",
  },
  boxContainer: {
    backgroundColor: "white",
    padding: 15,
    borderRadius: 10,
    height: "100%",
    boxSizing: "border-box",
    overflowY: "auto",
  },
};

export const DisableElements = ({ children, disabled }) => {
  return (
    <Box
      style={
        disabled
          ? {
              opacity: 0.5,
              pointerEvents: "none",
            }
          : {}
      }
    >
      {children}
    </Box>
  );
};

const Admin = ({
  orders = [],
  getAdminByOrderId = () => {},
  adminDeleteByOrderId = () => {},
  adminChangeStatusByOrderId = () => {},
  balance = "-",
  withdrawBalance = () => {},
  reloadStates = () => {},
  nonUSA,
  setNonUSA,
  prices,
  setPrices,
  adminChangePrices,
  downloadCSV = () => {},
}) => {
  // const [adminOrders, setAdminOrders] = useState({});

  const [filteredOrders, setFilteredOrders] = React.useState(orders);

  const [order, setOrder] = React.useState(null);
  const [checkBox, setCheckBox] = useState(true);
  const [page, setPage] = React.useState(1);
  const [random, setRandom] = React.useState(1);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [withdrawLoading, setWithdrawLoading] = React.useState(false);
  const [csvLoading, setCsvLoading] = React.useState(false);
  const [statusChange, setStatusChange] = React.useState("Select");
  const [filterStatus, setFilterStatus] = React.useState("All");
  const [loadingIdx, setLoadingIdx] = React.useState(null);

  const handleStatusChange = (value) => {
    setStatusChange(value);
  };
  const handleFilterStatus = (value) => {
    setFilterStatus(value);
  };

  const handleFilter = () => {
    if (filterStatus == "All") return setFilteredOrders(orders);
    let filtered = orders.filter(
      (o) => o.status.toLowerCase() == filterStatus.toLowerCase()
    );
    setFilteredOrders(filtered);
  };

  const handleSearch = (ordr) => {
    let page = filteredOrders.findIndex(
      (o) => o._id.toLowerCase() == ordr._id.toLowerCase()
    );
    if (page !== -1) {
      setPage(page + 1);
    }
  };

  const handleSaveStatus = async () => {
    if (statusChange == "Select") return;
    setLoadingSave(true);

    try {
      await adminChangeStatusByOrderId({
        id: order?._id,
        status: statusChange,
      });
      setRandom(Math.random());
      reloadStates();
    } catch {}
    setLoadingSave(false);
  };

  const handleDelete = async (id) => {
    setLoadingDelete(true);
    try {
      await adminDeleteByOrderId(id);
    } catch {}
    reloadStates();
    setLoadingDelete(false);
  };

  const handleDownload = async () => {
    setCsvLoading(true);
    try {
      await downloadCSV();
    } catch {}
    reloadStates();
    setCsvLoading(false);
  };

  const handleWithdrawBalance = async () => {
    try {
      setWithdrawLoading(true);
      await withdrawBalance();
      reloadStates();
    } catch {}
    setWithdrawLoading(false);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCheckBox = () => {
    setCheckBox(!checkBox);
  };

  const handlerPrices =
    (idx) =>
    ({ currentTarget: { value } }) => {
      if (!/^(\d*|(\d+\.\d*))$/.test(value.toString())) return;
      let _prices = [...prices];
      _prices[idx] = value;
      setPrices(_prices);
    };

  const handlerNonUSA = ({ currentTarget: { value } }) => {
    if (!/^(\d*|(\d+\.\d*))$/.test(value.toString())) return;
    setNonUSA(value);
  };

  const fetchOrder = async () => {
    if (filteredOrders.length > 0) {
      setOrder(null);

      let idx = 0;
      if (filteredOrders.length > page - 1 && page - 1 > 0) idx = page - 1;
      let id = filteredOrders[idx]._id;
      let data = await getAdminByOrderId(id);
      setOrder(data);
      setStatusChange(data?.status);
    }
  };

  const handlerAdminChangePrices = (location, value) => async () => {
    try {
      if (value <= 0) return;

      setLoadingIdx(location);
      await adminChangePrices({
        location,
        value,
      });
    } catch {}
    setLoadingIdx(null);
  };

  useEffect(() => {
    fetchOrder();
  }, [page, filteredOrders, random]);

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);
  // if (filteredOrders.length == 0) {
  //   return <NotFound />;
  // }

  let cond = filteredOrders && filteredOrders.length == 0;

  return (
    <>
      <Container disableGutters maxWidth="md">
        {filteredOrders.length > 1 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              padding: 20,
              borderRadius: 10,
            }}
          >
            <Pagination
              count={filteredOrders.length}
              page={page}
              onChange={handleChange}
            />
          </Box>
        )}

        <Box mt={2} />
        <Box style={styles.boxContainer}>
          {order ? (
            <>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "1.5rem" }} fontWeight="bold">
                  Controls
                </Typography>
                <Switch checked={checkBox} onChange={handleCheckBox} />
              </Box>
              <DisableElements disabled={checkBox}>
                <Grid container spacing={2}>
                  <Grid item sm md lg xl>
                    <DisableElements disabled={cond}>
                      <ChangeStatus
                        statusChange={statusChange}
                        handleStatusChange={handleStatusChange}
                        handleSaveStatus={handleSaveStatus}
                        loading={loadingSave}
                      />
                    </DisableElements>
                  </Grid>
                  <Grid item sm md lg xl>
                    <DisableElements disabled={cond}>
                      <DeleteOrder
                        adminDeleteByOrderId={handleDelete}
                        _id={order._id}
                        loading={loadingDelete}
                      />
                    </DisableElements>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                      justifyContent: "flex-end",
                    }}
                    item
                    sm
                    md
                    lg
                    xl
                  >
                    <DisableElements disabled={cond}>
                      <ButtonIndicator
                        style={{ height: "100px" }}
                        onClick={handleDownload}
                        fullWidth
                        variant="contained"
                        disabled={csvLoading}
                        color="primary"
                        disableElevation
                        type="over"
                      >
                        {"Download Orders as CSV"}
                      </ButtonIndicator>
                    </DisableElements>
                  </Grid>
                </Grid>
                <Box mt={2} />
                <Grid container spacing={2}>
                  <Grid item sm md lg xl>
                    <DisableElements disabled={cond}>
                      <SearchByOrderId
                        handleSearch={handleSearch}
                        value={order._id}
                        loading={false}
                        items={filteredOrders}
                      />
                    </DisableElements>
                  </Grid>
                  <Grid item sm md lg xl>
                    <ChangeStatus
                      statusChange={filterStatus}
                      handleStatusChange={handleFilterStatus}
                      handleSaveStatus={handleFilter}
                      loading={false}
                      buttonLabel="Filter"
                      title="Filter By Status"
                      items={[
                        "All",
                        "Pending",
                        "Ordered",
                        "In Progress",
                        "Delivered",
                      ]}
                    />
                  </Grid>
                  <Grid item sm md lg xl>
                    <WithdrawAmount
                      balance={balance}
                      withdraw={handleWithdrawBalance}
                      loading={withdrawLoading}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} />
                <Grid container spacing={2} alignItems={"flex-end"}>
                  {GTypesItem.map((title, idx) => {
                    return (
                      <Grid item sm md lg xl>
                        <PricesUpdate
                          key={idx}
                          index={idx}
                          loading={loadingIdx}
                          onChange={handlerPrices(idx)}
                          onSave={handlerAdminChangePrices(
                            idx,
                            parseFloat(prices[idx])
                          )}
                          title={title}
                          value={prices[idx]}
                        />
                      </Grid>
                    );
                  })}

                  <Grid item sm md lg xl>
                    <PricesUpdate
                      index={4}
                      loading={loadingIdx}
                      onChange={handlerNonUSA}
                      onSave={handlerAdminChangePrices(4, parseFloat(nonUSA))}
                      title={"Non USA Shipping"}
                      value={nonUSA}
                    />
                  </Grid>
                </Grid>
              </DisableElements>
            </>
          ) : (
            <Skeleton
              variant="rectangular"
              animation="pulse"
              style={{ borderRadius: 10 }}
              width={"100%"}
              height={150}
            />
          )}
        </Box>

        <Box mt={2} />

        {filteredOrders && filteredOrders.length == 0 ? (
          <NotFound />
        ) : (
          <>
            <Grid container alignItems="stretch" spacing={2}>
              <Grid item sm md lg xl>
                <Box style={styles.boxContainer}>
                  {order ? (
                    <TitledImage
                      image={`${order?.image}`}
                      // image={`data:image/png;base64,${order?.image}`}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      animation="pulse"
                      style={{ borderRadius: 10 }}
                      width={"100%"}
                      height={400}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item sm md lg xl>
                <Box style={styles.boxContainer}>
                  {order ? (
                    <Content {...order} />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      animation="pulse"
                      style={{ borderRadius: 10 }}
                      width={"100%"}
                      height={400}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default Admin;

// <Grid container alignItems="center" justifyContent="space-between">
// <Grid item style={{ display: "flex", alignItems: "center" }}>
//   <Typography fontWeight="bold">Order ID:</Typography>
//   <Typography paddingX="10px">456987</Typography>
// </Grid>
// <Grid item style={{ display: "flex", alignItems: "center" }}>
//   <Typography fontWeight="bold">Status:</Typography>
//   <Typography paddingX="10px">Ordered</Typography>
// </Grid>
// </Grid>
// <Box mt={2} />
// <Box style={{ display: "flex", alignItems: "flex-start" }}>
// <Typography fontWeight="bold">Address:</Typography>
// <Typography paddingX="10px">
//   Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima
//   error modi odio enim corporis dolores temporibus. Officiis nisi
//   ducimus doloremque.
// </Typography>
// </Box>
// <Box mt={2} />
// <Grid container spacing={1}>
// <Grid item sm md lg xl>
//   <TitledImage />
// </Grid>
// <Grid item sm md lg xl>
//   <Content />
// </Grid>
// </Grid>

const Content = ({
  status = "status",
  _id = "id",
  size = "size",
  type = "type",
  color = "color",
  address = "address",
  price = "-",
  orderNumber = "-",
  priceConflict = false,
  shippingName = "",
  removeBackground = false,
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="space-between"
    >
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Status:</Typography>
        <Typography paddingX="10px">{status}</Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Order ID:</Typography>
        <Typography paddingX="10px">{_id}</Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Remove Background:</Typography>
        <Typography paddingX="10px">
          {removeBackground ? "Yes" : "No"}
        </Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Shipping Name:</Typography>
        <Typography paddingX="10px">{shippingName}</Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Order number:</Typography>
        <Typography paddingX="10px">{orderNumber}</Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Price Paid:</Typography>
        <Typography paddingX="10px" color={priceConflict ? "red" : "black"}>
          {price}
        </Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Garment Size:</Typography>
        <Typography paddingX="10px">{size}</Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Garment Type:</Typography>
        <Typography paddingX="10px">{type}</Typography>
      </Grid>
      <Grid item style={styles.row}>
        <Typography fontWeight="bold">Garment Color:</Typography>
        <Typography paddingX="10px">{color}</Typography>
      </Grid>

      <Grid item style={styles.address}>
        <Typography fontWeight="bold">Address:</Typography>
        <Typography paddingX="10px">{address}</Typography>
      </Grid>
    </Grid>
  );
};

<Grid container direction="column"></Grid>;

const TitledImage = ({ image }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography fontWeight="bold">NFT Image</Typography>
      </Grid>
      <Grid item style={{ minHeight: "300px" }}>
        <img src={image} alt="nft image" width="100%" />
      </Grid>
    </Grid>
  );
};

const ChangeStatus = ({
  handleStatusChange,
  handleSaveStatus,
  statusChange,
  loading,
  title = "Change status",
  buttonLabel = "Save",
  items = ["Pending", "Ordered", "In Progress", "Delivered"],
}) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography fontWeight="bold">{title}</Typography>
      </Grid>
      <Grid item>
        <Box style={{ backgroundColor: "black", borderRadius: 5 }}>
          <MenuItems
            items={items}
            fn={handleStatusChange}
            value={statusChange}
          />
        </Box>
      </Grid>
      <Box mt={1} />
      <Grid item>
        <ButtonIndicator
          onClick={handleSaveStatus}
          fullWidth
          variant="contained"
          disabled={loading}
          color="primary"
          disableElevation
          type="over"
        >
          {buttonLabel}
        </ButtonIndicator>
      </Grid>
    </Grid>
  );
};

const DeleteOrder = ({ adminDeleteByOrderId, _id, loading }) => {
  const [input, setInput] = useState("");

  const handleInput = ({ currentTarget: { value } }) => {
    setInput(value);
  };

  const handleDelete = async () => {
    if (input === _id) await adminDeleteByOrderId(_id);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography fontWeight="bold">Delete Order</Typography>
      </Grid>
      <Grid item>
        <Input
          style={{
            minHeight: 35,
            padding: "0px 10px",
            border: "1px solid black",
            borderRadius: 5,
          }}
          value={input}
          onChange={handleInput}
          fullWidth
          disableUnderline
          placeholder={"Please type order Id here"}
        />
      </Grid>
      <Box mt={1} />

      <ButtonIndicator
        onClick={handleDelete}
        fullWidth
        variant="contained"
        disabled={loading}
        color="primary"
        disableElevation
        type="over"
      >
        Delete
      </ButtonIndicator>
    </Grid>
  );
};

// const filter = createFilterOptions();

const useStyles = makeStyles({
  textField: {
    minHeight: 35,
    padding: "0px 10px",
    border: "1px solid black",
    borderRadius: 5,
  },
});

const SearchByOrderId = ({
  handleSearch,
  _id,
  loading,
  items = [],
  fn = () => {},
  value2 = "",
}) => {
  const [input, setInput] = useState("");
  const [value, setValue] = React.useState(null);

  const classes = useStyles();

  const handleInput = ({ currentTarget: { value } }) => {
    setInput(value);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Typography fontWeight="bold">Search Order</Typography>
        </Grid>
        <Grid item>
          <Autocomplete
            fullWidth
            style={{ width: "100%" }}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  _id: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue({
                  _id: newValue.inputValue,
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = options.filter((item) =>
                item._id.includes(params.inputValue)
              );

              const { inputValue } = params;
              // Suggest the creation of a new value
              // const isExisting = options.some(
              //   (option) => inputValue === option._id
              // );
              // if (inputValue !== "" && !isExisting) {
              //   filtered.push({
              //     inputValue,
              //     _id: `Add "${inputValue}"`,
              //   });
              // }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={items}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              // if (typeof option === "string") {
              //   return option;
              // }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option._id;
            }}
            renderOption={(props, option) => <li {...props}>{option._id}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                style={{
                  minHeight: 35,
                  padding: "0px 10px",
                  border: "1px solid black",
                  borderRadius: 5,
                  width: "100%",
                }}
                fullWidth
                focused
                variant="standard"
                {...params}
              />
            )}
          />
        </Grid>
        <Box mt={1} />

        <ButtonIndicator
          onClick={() => {
            handleSearch(value);
          }}
          fullWidth
          variant="contained"
          disabled={loading}
          color="primary"
          disableElevation
          type="over"
        >
          Search
        </ButtonIndicator>
      </Grid>
    </>
  );
};

//   function Search() {
//   const [value, setValue] = React.useState(null);

//   return (

//   );
// }

const WithdrawAmount = ({
  balance = "0.0",
  withdraw = () => {},
  loading = false,
}) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography fontWeight="bold">Balance</Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            // minHeight: 35,
            padding: "6px 15px",
            verticalAlign: "center",
            border: "1px solid black",
            borderRadius: 5,
          }}
        >
          {balance}
        </Typography>
      </Grid>
      <Box mt={1} />

      <ButtonIndicator
        onlyDisable={!(parseFloat(balance) > 0)}
        onClick={withdraw}
        fullWidth
        variant="contained"
        disabled={loading}
        color="primary"
        disableElevation
        type="over"
      >
        Withdraw
      </ButtonIndicator>
    </Grid>
  );
};

const PricesUpdate = ({
  index,
  title = "title",
  value = "0.0",
  onChange = () => {},
  onSave = () => {},
  loading = null,
}) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography fontWeight="bold">{title}</Typography>
      </Grid>
      <Grid item>
        <Input
          style={{
            minHeight: 35,
            padding: "0px 10px",
            border: "1px solid black",
            borderRadius: 5,
          }}
          value={value}
          onChange={onChange}
          fullWidth
          disableUnderline
          placeholder={"0.0"}
        />
      </Grid>
      <Box mt={1} />

      <ButtonIndicator
        onlyDisable={!(parseFloat(value) > 0)}
        onClick={onSave}
        fullWidth
        variant="contained"
        disabled={loading == index}
        color="primary"
        disableElevation
        type="over"
      >
        Save
      </ButtonIndicator>
    </Grid>
  );
};
