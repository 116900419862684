import React, { useEffect } from "react";
import {
  Box,
  Pagination,
  Container,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import NotFound from "../components/notFound/NotFound";
import ButtonIndicator from "../components/buttonIndicator/ButtonIndicator";
import { useState } from "react";
// import MenuItems from "../components/menu/Menu";
// import { getMethod } from "../utils/requests";

const styles = {
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    padding: "5px 0px",
  },
  address: {
    display: "flex",
    alignItems: "flex-start",
    padding: "5px 0px",
    borderBottom: "1px solid black",
  },
};

const Orders = ({ orders = [], getByOrderId = () => {}, buy }) => {
  const [page, setPage] = React.useState(1);
  const [random, setRandom] = React.useState(Math.random());
  const [order, setOrder] = React.useState(null);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const fetchOrder = async () => {
    if (orders.length > 0) {
      setOrder(null);
      let id = orders[page - 1]._id;
      let data = await getByOrderId(id);
      setOrder(data);
    }
  };

  const handlerBuy = async (id, price) => {
    await buy(id, price);
    setRandom(Math.random());
  };

  useEffect(() => {
    fetchOrder();
  }, [page, orders, random]);

  if (orders.length == 0) {
    return <NotFound />;
  }

  return (
    <>
      <Container disableGutters maxWidth="xs">
        {orders.length > 1 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              padding: 20,
              borderRadius: 10,
            }}
          >
            <Pagination
              count={orders.length}
              page={page}
              onChange={handleChange}
            />
          </Box>
        )}
        <Box mt={2} />
        <Box
          style={{
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            boxSizing: "border-box",
            overflowY: "auto",
          }}
        >
          {order ? (
            <Content {...order} handlerBuy={handlerBuy} />
          ) : (
            <Skeleton
              variant="rectangular"
              animation="pulse"
              style={{ borderRadius: 10 }}
              width={"100%"}
              height={700}
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default Orders;

const Content = ({
  status,
  _id,
  size,
  type,
  color,
  address,
  image,
  removeBackground,
  shippingName,
  price,
  priceConflict,
  orderNumber,
  priceToBePaid,
  handlerBuy = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    try {
      await handlerBuy(orderNumber, priceToBePaid);
    } catch {}
    setLoading(false);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justifyContent="space-between"
      >
        <Grid item style={styles.row}>
          {price === 0 ? (
            <div style={{ ...styles.address, borderBottom: undefined }}>
              <Typography fontWeight="bold">Status:</Typography>
              <Typography paddingX="10px">{status}</Typography>
            </div>
          ) : (
            <>
              <Typography fontWeight="bold">Status:</Typography>
              <Typography paddingX="10px">{status}</Typography>
            </>
          )}

          {price === 0 && (
            <ButtonIndicator
              fullWidth
              style={{
                padding: "2px 8px",
                borderRadius: 10,
              }}
              variant="contained"
              color="primary"
              onClick={onClick}
              disabled={loading}
              disableElevation
              type="over"
            >
              Checkout
            </ButtonIndicator>
          )}
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Order ID:</Typography>
          <Typography paddingX="10px">{_id}</Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Shipping Name:</Typography>
          <Typography paddingX="10px">{shippingName}</Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Remove Background:</Typography>
          <Typography paddingX="10px">
            {removeBackground ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Garment Size:</Typography>
          <Typography paddingX="10px">{size}</Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Garment Type:</Typography>
          <Typography paddingX="10px">{type}</Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Garment Color:</Typography>
          <Typography paddingX="10px">{color}</Typography>
        </Grid>
        <Grid item style={styles.address}>
          <Typography fontWeight="bold">Address:</Typography>
          <Typography paddingX="10px">{address}</Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Paid:</Typography>
          <Typography
            paddingX="10px"
            style={{ color: priceConflict ? "red" : undefined }}
          >
            {price} ETH
          </Typography>
        </Grid>
        <Grid item style={styles.row}>
          <Typography fontWeight="bold">Cost:</Typography>
          <Typography
            paddingX="10px"
            style={{ color: priceConflict ? "red" : undefined }}
          >
            {priceToBePaid} ETH
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2} />
      <TitledImage
        image={`${image}`}
        // image={`data:image/png;base64,${image}`}
      />
    </>
  );
};

const TitledImage = ({ image }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography fontWeight="bold">NFT Image</Typography>
      </Grid>
      <Grid item>
        <img src={image} alt="nft image" width="100%" height="100%" />
      </Grid>
    </Grid>
  );
};
