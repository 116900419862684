import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Container,
  Dialog as MuiDialog,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import ButtonIndicator from "../buttonIndicator/ButtonIndicator";

const Dialog = ({ onClose, open, data, handlerBuy }) => {
  const {
    size,
    color,
    type,
    quantity,
    address,
    orderId,
    orderNumber,
    price,
    shippingFee,
  } = data;
  const [loading, setLoading] = React.useState(false);
  //   const handleClose = () => {
  //     onClose();
  //   };

  const onClick = async () => {
    setLoading(true);
    try {
      await handlerBuy(orderNumber);
    } catch {}
    setLoading(false);
  };

  const trunc = (text = "", n = 22) => {
    return text.slice(0, n) + (text.length > n ? "..." : "");
  };

  return (
    <MuiDialog onClose={onClose} open={open}>
      <Container
        disableGutters
        style={{
          backgroundColor: "white",
          width: "100vw",
          maxWidth: "400px",
          padding: 15,
        }}
        maxWidth="xs"
      >
        <Typography
          style={{ fontSize: "2rem", fontWeight: 500 }}
          color="primary"
        >
          Receipt
        </Typography>
        <Box mt={2} />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>Order ID:</Typography>
          }
          toolTip={orderId}
          item02={<Typography>{trunc(orderId)}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>
              Order Number:
            </Typography>
          }
          item02={<Typography>{orderNumber}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>
              Garment Type:
            </Typography>
          }
          toolTip={type}
          item02={<Typography>{trunc(type)}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>
              Garment Size:
            </Typography>
          }
          toolTip={size}
          item02={<Typography>{trunc(size)}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>Color:</Typography>
          }
          toolTip={color}
          item02={<Typography>{trunc(color)}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>Quantity:</Typography>
          }
          toolTip={quantity}
          item02={<Typography>{quantity}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>Address:</Typography>
          }
          toolTip={address}
          item02={<Typography>{trunc(address)}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>Price:</Typography>
          }
          toolTip={(parseFloat(price) - parseFloat(shippingFee)).toString()}
          item02={
            <Typography>
              {parseFloat(price) - parseFloat(shippingFee)}
            </Typography>
          }
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>
              Non USA Shipping Fee:
            </Typography>
          }
          toolTip={shippingFee.toString()}
          item02={<Typography>{shippingFee}</Typography>}
        />
        <Row
          item01={
            <Typography style={{ fontWeight: "bold" }}>Total:</Typography>
          }
          toolTip={price.toString()}
          item02={<Typography>{price}</Typography>}
        />
        <Box mt={2} />

        <ButtonIndicator
          fullWidth
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={loading}
          disableElevation
          type="over"
        >
          Checkout
        </ButtonIndicator>
      </Container>
    </MuiDialog>
  );
};

export default Dialog;

const Row = ({ item01, item02, toolTip = "tooltip" }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>{item01}</Grid>
      <Grid item>
        <Tooltip arrow title={toolTip}>
          {item02}
        </Tooltip>
      </Grid>
    </Grid>
  );
};
