import { Container, Typography, Box } from "@mui/material";

const Works = () => {
  const steps = [
    // How It works.
    "Connect your wallet",
    "Sign from your wallet to authenticate",
    "Upload a high resolution image of your favorite NFT (.jpeg or .png)",
    "Select if you want background to be cropped out",
    "Select garment size, type, and color",
    "Enter shipping information",
    "Place order & checkout",
  ];

  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" color="white">
          How It works ?
        </Typography>
        <Box mt={4} />
        {steps.map((t, i) => {
          return (
            <Box
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: 5,
                marginTop: 10,
              }}
            >
              <Typography>{`Step ${i + 1}: ${t}`}</Typography>
            </Box>
          );
        })}
      </Container>
    </>
  );
};

export default Works;
