import { Container } from "@mui/material";
import React from "react";
import man from "../assets/man.jpeg";
const About = () => {
  return (
    <>
      <Container style={{ color: "white" }} maxWidth="lg">
        <h2>About Us !</h2>
        <h3 style={{ textAlign: "center" }}>
          Welcome To <span id="W_Name1">{window.location.host}</span>
        </h3>
        <p>
          NFTshirt is a web3 commerce platform used for bringing quality garment
          printing to the metaverse.
        </p>
        <p>
          Many of the clothing solutions we have seen are overly complex and
          trying to provide onchain solutions for manufacturing, and with this
          project we want to create a fun and easy way for people to represent
          their favorite NFTs!
        </p>
        <p>
          This project is powered by{" "}
          <a
            href="https://www.F4milyMatters.com"
            style={{ color: "yellow" }}
            target={"_blank"}
          >
            F4mily Matters
          </a>
          , industry leading digital printers that believe quality garment
          printing should be more accessible. We dream of a world where anyone,
          anywhere can have their own designs printed on high-quality garments,
          using state-of-the-art digital printing techniques.
        </p>
        <p>
          At F4mily Matters, we specialize in producing premium apparel on a
          recurring schedule with expert creative support. When our company was
          founded, we operated out of a tiny storage unit. Today, our team
          prints garments for the biggest organizations in our region, including
          Microsoft, Bank of America, and the Charlotte Hornets. We believe in
          supporting the community we exist in, which makes these local
          partnerships even more rewarding.
        </p>
        <p>
          Our business is storytelling. We believe that we exist as a mechanism
          for creatives to tell their stories on garments. At F4mily Matters, we
          help brands use garments to mobilize their fans and make money through
          merchandise sales. We help businesses use garments to market their
          companies. We help companies use garments to build culture through
          uniforms. Regardless of your printing needs, we can help you tell your
          story.
          {/* <span
            style={{
              color: "blue",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Have a nice day !
          </span> */}
        </p>
        <p>
          Our business is storytelling. We believe that we exist as a mechanism
          for creatives to tell their stories on garments. At F4mily Matters, we
          help brands use garments to mobilize their fans and make money through
          merchandise sales. We help businesses use garments to market their
          companies. We help companies use garments to build culture through
          uniforms. Regardless of your printing needs, we can help you tell your
          story.
        </p>
        <br />
        <br />
        <p style={{ display: "flex", alignItems: "flex-end" }}>
          <img
            src={man}
            style={{ width: 150, borderRadius: 5, border: "5px solid white" }}
            alt="profile"
          />
          <div style={{ marginLeft: 15 }}>
            <div>Founded by:</div>
            <div>Samir Hamid</div>
            <div>
              Professional Story teller, Crypto Degen since 2017, NFT Degen
              since 2020
            </div>
          </div>
        </p>
      </Container>
    </>
  );
};

export default About;
