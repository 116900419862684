import { Button, CircularProgress } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
  },
  fabProgress: {
    color: "green",
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
    color: "green",
  },
  buttonProgress2nd: {
    marginRight: 10,
  },
  help: {
    position: "absolute",
    top: 8,
    right: 10,
    opacity: "0.5",
  },
  disabled: {
    backgroundColor: "lightgray !important",
  },
});

const ButtonIndicator = ({
  onClick = () => {},
  loaderSize = 20,
  label = "label",
  disabled: loading,
  children,
  containerStyle,
  onlyDisable = false,
  progressStyle,
  type = "left",
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div style={containerStyle} className={classes.wrapper}>
      <Button
        {...rest}
        classes={{
          disabled: classes.disabled,
        }}
        onClick={onClick}
        disabled={loading || onlyDisable}
      >
        {loading && type === "left" && (
          <CircularProgress
            style={progressStyle}
            size={loaderSize}
            className={classes.buttonProgress2nd}
          />
        )}
        {children ? children : label}
      </Button>
      {loading && type === "over" && (
        <CircularProgress
          // color="secondary"
          style={progressStyle}
          size={loaderSize}
          className={classes.buttonProgress}
        />
      )}
    </div>
  );
};

export default ButtonIndicator;
