import { saveAs } from "file-saver";

const host = process.env.REACT_APP_SERVER_API;

export const postMethod = async ({ uri, auth, body }) => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${host}${uri}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) return reject(res.statusText);

    const resJSON = await res.json();

    if (resJSON?.status === "error") reject(resJSON?.message);

    resolve(resJSON);
  });
};

export const getMethod = async ({ uri, auth }) => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${host}${uri}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });

    if (!res.ok) return reject(res.statusText);

    const resJSON = await res.json();

    if (resJSON?.status === "error") reject(resJSON?.message);

    resolve(resJSON);
  });
};

// var saveAs = require('file-saver');

// fetch('/download/urf/file', {
//   headers: {
//     'Content-Type': ''
//   },
//   responseType: 'blob'
// }).then(response => response.blob())
//   .then(blob => saveAs(blob, 'test.csv'));

export const getMethodCSV = async ({ uri, auth }) => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${host}${uri}`, {
      method: "GET",
      headers: {
        "Content-Type": "text/csv",
        Authorization: auth,
      },
    });

    if (!res.ok) return reject(res.statusText);

    const resBLOB = await res.blob();

    //  reject(resJSON?.message);
    saveAs(resBLOB, "orders.csv");
    resolve("");
  });
};

export const deleteMethod = async ({ uri, auth }) => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${host}${uri}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });

    if (!res.ok) return reject(res.statusText);

    const resJSON = await res.json();

    if (resJSON?.status === "error") reject(resJSON?.message);

    resolve(resJSON);
  });
};

export const putMethod = async ({ uri, auth, body }) => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${host}${uri}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) return reject(res.statusText);

    const resJSON = await res.json();

    if (resJSON?.status === "error") reject(resJSON?.message);

    resolve(resJSON);
  });
};
