import { Container, Box, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Container disableGutters maxWidth="xs">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Typography style={{ fontSize: "0.9rem" }}>
          Can not find any order
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;
