import { TablePagination, Box } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import useStyles from "./styles";

const WithPagination = ({
  rowsPerPage,
  page = 0,
  items = [],
  setPage = () => {},
  func = () => {},
  containerClassName = "",
}) => {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeIndex = (index) => {
    setPage(index);
  };
  return (
    <>
      <Box
        className={
          items.length > 0
            ? [classes.conatiner, containerClassName].join(" ")
            : classes.conatiner
        }
      >
        <SwipeableViews
          springConfig={{
            duration: "0.7s",
            easeFunction: "cubic-bezier(0.15, 0.3, 0.25, 1)",
            delay: "0s",
          }}
          index={page}
          onChangeIndex={handleChangeIndex}
        >
          {items.length > 0 ? (
            [...Array(Math.ceil(items.length / rowsPerPage)).keys()].map(
              (n, i) => {
                return (
                  <TabPanel
                    items={items.slice(
                      n * rowsPerPage,
                      n * rowsPerPage + rowsPerPage
                    )}
                    func={func}
                    index={n}
                    value={page}
                    key={n}
                  />
                );
              }
            )
          ) : (
            <></>
          )}
        </SwipeableViews>
      </Box>
      {rowsPerPage < items.length && (
        <>
          <Box mt={3} />
          <Box style={{ backgroundColor: "white", borderRadius: 5 }}>
            <TablePagination
              component="div"
              count={items.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              labelDisplayedRows={function ({ from, to, count }) {
                return `${from} of ${count !== -1 ? count : `more than ${to}`}`;
              }}
              rowsPerPageOptions={[]}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default WithPagination;

const TabPanel = (props) => {
  const { value, index, items, func, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {items.length > 0 &&
            items.map((val, idx) => {
              let component = func(val, idx);
              return <>{component}</>;
            })}
        </>
      )}
    </div>
  );
};
