import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff",
    },
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    // fontFamily: "avenir",
    // body1: {
    //   fontSize: "1.25rem",
    //   color: "white",
    //   fontWeight: 500,
    //   letterSpacing: 0.5,
    // },
  },
  //   mixins: {
  //     toolbar: {
  //       minHeight: 80,
  //     },
  //   },
});
