// Tshirt: .01E
// Long Sleeve:  .015E
// Sweatshirt: .02E
// Hooded Sweatshirt:  .025E

// export const GTypesPrice = {
//   tShirt: 1,
//   sleeve: 2,
//   sweatShirt: 3,
//   hooded: 4,
// };

export const GTypesItem = [
  "Premium Cotton T-shirt",
  "Premium Cotton Hoodie",
  "Premium Long Sleeve",
  "Premium Sweat Shirt",
];

export const GSTypesItem = [
  "Extra Small",
  "Small",
  "Medium",
  "Large",
  "Extra Large",
  "XL",
  "2XL",
  "3X",
];

export const GCTypesItems = [
  "Black",
  "White",
  "Red",
  "Heather Grey",
  "Navy Blue",
];
