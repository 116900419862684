import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function MenuItems({
  items = ["item01", "item02", "item03"],
  fn,
  value,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [selected, setSelected] = React.useState("Select");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => () => {
    if (item) fn(item);
    setAnchorEl(null);
  };

  const len = 13;

  return (
    <div>
      <Button
        style={{ minHeight: 45 }}
        fullWidth
        style={{ justifyContent: "space-between" }}
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="secondary"
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
      >
        {value.length > len ? value.slice(0, len) + "..." : value}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {items.map((item, i) => {
          return (
            <MenuItem
              selected={value == item}
              key={i}
              onClick={handleClose(item)}
            >
              {item}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
