import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import {
  Button,
  Container,
  IconButton,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import logo from "../../assets/logo.png";

import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import discord from "../../assets/discord.png";

const useStyles = makeStyles({
  marginTop: (props) => ({
    ...props?.toolbar,
  }),
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainHeader({
  connectMetaMask,
  account,
  isAdmin,
  signature,
  auth = () => {},
}) {
  const theme = useTheme();
  const classes = useStyles(theme.mixins);

  const history = useHistory();

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="primary" position="fixed">
          <Toolbar>
            <Container
              style={{ display: "flex", alignItems: "center" }}
              maxWidth="lg"
            >
              <Box
                style={{
                  alignSelf: "center",
                  fontFamily: "'Great Vibes', cursive",
                  fontSize: "2.5rem",
                  color: "white",
                }}
              >
                <img src={logo} alt="logo" width="100px" />
              </Box>

              <Tabs
                style={{ marginLeft: 20 }}
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                textColor="secondary"
              >
                <Tab
                  style={{ color: "white" }}
                  label="Home"
                  {...a11yProps(0)}
                  onClick={() => {
                    history.push("/");
                  }}
                />
                {account && signature && (
                  <Tab
                    style={{ color: "white" }}
                    label="Orders"
                    {...a11yProps(1)}
                    onClick={() => {
                      history.push("/orders");
                    }}
                  />
                )}

                <Tab
                  style={{ color: "white" }}
                  label="About us"
                  {...a11yProps(1)}
                  onClick={() => {
                    history.push("/about-us");
                  }}
                />
                <Tab
                  style={{ color: "white" }}
                  label="How it works"
                  {...a11yProps(1)}
                  onClick={() => {
                    history.push("/how-it-works");
                  }}
                />

                {account && isAdmin && (
                  <Tab
                    style={{ color: "white" }}
                    label="Admin"
                    {...a11yProps(1)}
                    onClick={() => {
                      history.push("/admin");
                    }}
                  />
                )}
              </Tabs>

              <Box
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box margin="0px 10px" display={"flex"} alignItems={"center"}>
                  <a
                    href="https://twitter.com/f4milymatters"
                    target={"_blank"}
                    style={{
                      margin: "0px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TwitterIcon style={{ color: "white" }} />
                  </a>
                  <a
                    style={{
                      margin: "0px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    href="https://www.instagram.com/f4milymatters/"
                    target={"_blank"}
                  >
                    <InstagramIcon style={{ color: "white" }} />
                  </a>
                  <a
                    style={{
                      margin: "0px 6px",
                      // backgroundColor: "red",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    href="https://discord.gg/MVapCJKp"
                    target={"_blank"}
                  >
                    <img src={discord} width="20px" alt="discord" />
                  </a>
                </Box>
                {account && (
                  <Button
                    id="auth"
                    style={{
                      maxHeight: 40,
                      // background: "red",
                      minWidth: 5,
                      padding: `6px ${signature ? "10px" : "16px"}`,
                      textTransform: "none",
                      fontWeight: 500,
                      borderRadius: 20,
                      marginRight: 10,
                    }}
                    onClick={auth}
                    variant="contained"
                  >
                    {signature ? (
                      <CheckCircleOutlinedIcon color="secondary" />
                    ) : (
                      "Authenticate"
                    )}
                  </Button>
                )}
                <Button
                  style={{
                    maxHeight: 40,
                    background: "#bf1e2e",
                  }}
                  onClick={connectMetaMask}
                  variant="contained"
                >
                  {account
                    ? account.slice(0, 8) +
                      "..." +
                      account.slice(account.length - 5)
                    : "Connect Wallet"}
                </Button>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
      <Box mt={"100px"} />
    </>
  );
}
