import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import discord from "../../assets/discord.png";

function MainFooter() {
  return (
    <>
      <Box mt={4} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "black",
          padding: "20px 10px",
        }}
      >
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item>
            <a
              href="https://twitter.com/f4milymatters"
              target={"_blank"}
              style={{ margin: "0px 5px" }}
            >
              <TwitterIcon style={{ color: "white", fontSize: "2rem" }} />
            </a>
          </Grid>
          <Grid item>
            <a
              style={{ margin: "0px 5px" }}
              href="https://www.instagram.com/f4milymatters/"
              target={"_blank"}
            >
              <InstagramIcon style={{ color: "white", fontSize: "2rem" }} />
            </a>
          </Grid>
          <Grid item>
            <a
              style={{ margin: "0px 8px" }}
              href="https://discord.gg/MVapCJKp"
              target={"_blank"}
            >
              <img src={discord} width="30px" alt="discord" />
            </a>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Typography color="white">
          © 2021 NFT Shirt. All rights reserved.
        </Typography>
      </Box>
    </>
  );
}

export default MainFooter;
