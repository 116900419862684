import { CssBaseline } from "@mui/material";
import { Redirect, Route, Switch } from "react-router-dom";
import MainHeader from "./components/mainHeader/MainHeader";
import Admin, { DisableElements } from "./pages/Admin";
import Home from "./pages/Home";

import { ToastContainer, toast } from "react-toastify";
import Web3 from "web3";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { contractAbi, contractAddress } from "./config";
import {
  deleteMethod,
  getMethod,
  getMethodCSV,
  postMethod,
  putMethod,
} from "./utils/requests";
import Orders from "./pages/Orders";
import About from "./pages/About";
import MainFooter from "./components/mainFooter/MainFooter";
import Works from "./pages/Works";
import Alert from "./components/alert/Alert";

function App() {
  const [chainId, setChainId] = useState(null);
  const [iWeb3, setIWeb3] = useState(null);
  const [method, setMethod] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [getBalance, setGetBalance] = useState("0");

  // order related
  const [image, setImage] = useState("");
  // const [size, setSize] = useState("Select");
  // const [color, setColor] = useState("Select");
  // const [type, setType] = useState("Select");
  const [quantity, setQuantity] = useState("1");
  // new struct
  const [orderInfo, setOrderInfo] = useState({});

  const [country, setCountry] = useState({
    label: "United States",
    value: "US",
  });
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [shippingName, setShippingName] = useState("");
  const [removeBackground, setRemoveBackground] = useState(false);

  const [_signature, setSignature] = useState(null);
  const [orderIds, setOrderIds] = useState([]);
  const [adminOrderIds, setAdminOrderIds] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [prices, setPrices] = useState([0, 0, 0, 0]);

  const [nonUSA, setNonUSA] = useState(0);

  const [random, setRandom] = useState(0);

  const SIGNED_ORDER_DEFAULT = {
    orderId: "",
    size: "",
    type: "",
    color: "",
    quantity: "",
    address: "",
    price: 0,
  };

  const [signedOrder, setSignedOrder] = useState(SIGNED_ORDER_DEFAULT);
  const [dialog, setDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const resetFieldsToDefault = () => {
    setSignedOrder(SIGNED_ORDER_DEFAULT);

    setImage("");
    // setSize("Select");
    // setColor("Select");
    // setType("Select");
    setQuantity("1");

    setCountry({
      label: "United States",
      value: "US",
    });
    setState("");
    setCity("");
    setStreet("");
    setZipcode("");
  };

  useEffect(() => {
    account && method && fireToast();
  }, [method]);

  useEffect(async () => {
    loadWeb3();
  }, [account, chainId]);

  async function loadWeb3() {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      setIWeb3(web3);
      window.web3 = web3;
      try {
        loadBlockchainData();
        getCurrentAddressConnected();
        addAccountsAndChainListener();
      } catch (error) {
        console.error(error);
      }
    } else {
      swal(
        "",
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        "error"
      );
    }
  }
  const loadBlockchainData = async () => {
    const contract = new window.web3.eth.Contract(contractAbi, contractAddress);
    setContract(contract);
    const chainId = await window.web3.eth.getChainId();
    setChainId(chainId);

    chainId === 1 ? setMethod("success") : setMethod("error");

    // method && fireToast()

    if (chainId === 1) {
      // const totalSupply = await contract.methods.totalSupply().call();
      // setTotalSupply(totalSupply);
      // const price = await contract.methods.getNFTPrice().call();
      // setPrice(price);
      // const displayPrice = window.web3.utils.fromWei(price, "ether");
      // setDisplayPrice(displayPrice);
      // // console.log(account);
      // if (account) {
      //   const getBalance = await window.web3.eth.getBalance(account);
      //   setGetBalance(
      //     parseFloat(window.web3.utils.fromWei(getBalance, "ether"))
      //       .toFixed(2)
      //       .toString()
      //   );
      // }
      // //event will be fired by the smart contract when a new GnarNarwhal is minted
      // contract.events
      //   .TheGnarNarwhalsProjectMinted()
      //   .on("data", async function (result) {
      //     setTotalSupply(result.returnValues[0]);
      //   })
      //   .on("error", console.error);
    }
  };

  const getCurrentAddressConnected = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addAccountsAndChainListener = async () => {
    //this event will be emitted when the currently connected chain changes.
    window.ethereum.on("chainChanged", (_chainId) => {
      window.location.reload();
    });

    // this event will be emitted whenever the user's exposed account address changes.
    window.ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);

        method && fireToast();
      } catch (error) {
        if (error.code === 4001) {
          swal("Request to access account denied!", "", "error");
        }
      }
    }
  };

  // async function mint(mintCount) {
  //   if (contract) {
  //     if (chainId === 3) {
  //       if (mintCount === 0) {
  //         swal("Minimum mint amount is 1 GnarNarwhal", "", "info");
  //       } else {
  //         try {
  //           const finalPrice = Number(price) * mintCount;
  //           await contract.methods
  //             .mintTheGnarNarwhalsProject(mintCount)
  //             .send({ from: account, value: finalPrice });
  //         } catch (error) {
  //           if (error.code === 4001) {
  //             swal("Transaction Rejected!", "", "error");
  //           } else {
  //             swal("Transaction Failed!", "", "error");
  //             console.log(error);
  //           }
  //         }
  //       }
  //     } else {
  //       swal("Please switch to mainnet to mint GnarNarwhal", "", "error");
  //     }
  //   } else {
  //     swal(
  //       "",
  //       "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
  //       "error"
  //     );
  //   }
  // }

  const fireToast = () => {
    toast[method](
      `You are ${method === "error" ? "not" : ""} connected to mainnet`,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      }
    );
  };

  const uploadImage = async (image) => {
    let signature = await getSignature();
    if (iWeb3 && account && signature) {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "l4lnpbob");
      data.append("cloud_name", "du06iefj9");

      return new Promise(async (resolve, reject) => {
        const res = await fetch(
          `https://api.cloudinary.com/v1_1/du06iefj9/image/upload`,
          {
            method: "POST",
            body: data,
          }
        );

        if (!res.ok) return reject(res.statusText);

        const resJSON = await res.json();

        resolve(resJSON.secure_url);
      });
    }
  };

  const sign = async () => {
    let signature = await getSignature();
    if (iWeb3 && account && signature) {
      try {
        let size = "",
          type = "",
          color = "";

        let keys = Object.keys(orderInfo);

        let ret = false;

        for (let i = 0; i < keys.length; i++) {
          if (
            [
              orderInfo[keys[i]].size.toLowerCase(),
              orderInfo[keys[i]].type.toLowerCase(),
              orderInfo[keys[i]].color.toLowerCase(),
            ].includes("select")
          ) {
            setAlertOpen(true);
            ret = true;
            break;
          }
        }
        if (ret) return;

        if (
          [state, city, street, zipcode, image, shippingName].includes("") ||
          [size, type, color].includes("Select")
        )
          return setAlertOpen(true);
        // return alert("Please provide valid information.");

        keys.forEach((v, i) => {
          let end = i + 1 < keys.length ? "," : "";

          size += orderInfo[v].size + end;
          type += orderInfo[v].type + end;
          color += orderInfo[v].color + end;
        });

        let url = await uploadImage(image);

        let {
          data: { order, priceToBePaid },
        } = await postMethod({
          uri: "user/order",
          body: {
            size,
            type,
            color,
            quantity: parseInt(quantity),
            address: [country.label, state, city, street, zipcode].join(","),
            user: account,
            shippingName: shippingName,
            removeBackground: removeBackground,
            image: url,
          },
          auth: signature,
        });

        setSignedOrder({
          size,
          type,
          color,
          quantity,
          address: [country.label, state, city, street, zipcode].join(","),
          orderId: order._id,
          orderNumber: order.orderNumber,
          price: priceToBePaid,
          shippingName: shippingName,
          removeBackground: removeBackground,
        });

        handlerOpenDialog();
        setRandom(Math.random());
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handlerOpenDialog = () => {
    setDialog(true);
  };

  const getSignature = async () => {
    if (account) {
      if (_signature) {
        return _signature;
      } else {
        let signature = await iWeb3.eth.personal.sign(
          process.env.REACT_APP_AUTH,
          account
        );
        setSignature(`${signature}-${account}`);
        setRandom(Math.random());
        return `${signature}-${account}`;
      }
    }
    return null;
  };

  const getOrderIds = async () => {
    // let signature = await getSignature();

    if (_signature) {
      const ids = await getMethod({ uri: "user/orderids", auth: _signature });
      if (ids) {
        setOrderIds(ids?.data ?? []);
      }
    }
  };

  const getPrices = async () => {
    if (_signature) {
      const { data } = await getMethod({
        uri: "user/prices",
        auth: _signature,
      });
      if (data) {
        setPrices(data?.amounts ?? [0, 0, 0, 0]);
        setNonUSA(data?.nonUSA ?? 0);
      }
    }
  };

  const fetchByOrderId = async (id) => {
    // let signature = await getSignature();

    if (_signature) {
      const data = await getMethod({
        uri: `user/order/${id}`,
        auth: _signature,
      });
      if (data) {
        return data?.data ?? {};
      }
      return {};
    }
  };

  const getAdminByOrderId = async (id) => {
    if (_signature) {
      const data = await getMethod({
        uri: `admin/order/${id}`,
        auth: _signature,
      });
      if (data) {
        return data?.data ?? {};
      }
      return {};
    }
  };

  const adminDeleteByOrderId = async (id) => {
    if (_signature) {
      const data = await deleteMethod({
        uri: `admin/order/${id}`,
        auth: _signature,
      });
      if (data) {
        let ids = [...adminOrderIds];
        let filtered = ids.filter((id) => id._id !== data.data._id);
        setAdminOrderIds(filtered);
        let idsUser = [...orderIds];
        let filteredUser = idsUser.filter((id) => id._id !== data.data._id);
        setOrderIds(filteredUser);
      }
    }
  };

  const adminFetchByOrderId = async () => {
    // let signature = await getSignature();

    if (_signature) {
      const ids = await getMethod({ uri: `admin/orderids`, auth: _signature });
      if (ids) {
        setAdminOrderIds(ids?.data ?? []);
      }
    }
  };

  const adminChangeStatusByOrderId = async (body) => {
    if (_signature) {
      const data = await putMethod({
        uri: `admin/status`,
        auth: _signature,
        body,
      });
    }
  };

  const adminChangePrices = async (body) => {
    if (_signature) {
      const { data } = await putMethod({
        uri: `admin/prices`,
        auth: _signature,
        body,
      });
      if (data) {
        setPrices(data?.amounts ?? [0, 0, 0, 0]);
        setNonUSA(data?.nonUSA ?? 0);
      }
    }
  };

  const whoAmI = async () => {
    if (_signature) {
      const data = await getMethod({
        uri: `admin/whoami`,
        auth: _signature,
      });
      if (data) {
        const {
          data: { whoami },
        } = data;
        setIsAdmin(whoami ?? false);
      }
    }
  };

  const downloadCSV = async () => {
    if (_signature) {
      // const data =
      await getMethodCSV({
        uri: `admin/csv`,
        auth: _signature,
      });
      // if (data) {
      //   const {
      //     data: { whoami },
      //   } = data;
      //   setIsAdmin(whoami ?? false);
      // }
    }
  };

  const buy = async (id) => {
    if (contract && parseFloat(signedOrder.price) > 0) {
      setLoading(true);
      try {
        await contract.methods.buy(id).send({
          from: account,
          value: Web3.utils.toWei(signedOrder.price.toString(), "ether"),
        });
        resetFieldsToDefault();
        swal(
          "Congratulations!",
          "Your order has been placed. Thankyou for shopping with us! You can track status of your order on order page.",
          "success"
        );
      } catch {}
      setLoading(false);
    }
  };

  const buyVanilla = async (id, price) => {
    if (contract && parseFloat(price) > 0) {
      setLoading(true);
      try {
        await contract.methods.buy(id).send({
          from: account,
          value: Web3.utils.toWei(price.toString(), "ether"),
        });
        resetFieldsToDefault();
        swal(
          "Congratulations!",
          "Your order has been placed. Thankyou for shopping with us! You can track status of your order on order page.",
          "success"
        );
      } catch {}
      setLoading(false);
    }
  };

  const withdrawBalance = async () => {
    if (contract) {
      await contract.methods.withdrawETH().send({
        from: account,
      });
    }
  };

  const getBalanceFromContract = async () => {
    if (contract) {
      let balance = await contract.methods.getBalance().call();
      setGetBalance(Web3.utils.fromWei(balance, "ether"));
    }
  };

  useEffect(() => {
    if (account && _signature) {
      getOrderIds();
      getPrices();
    }
  }, [_signature, account, random]);

  useEffect(() => {
    if (account && isAdmin && _signature) {
      adminFetchByOrderId();
    }
  }, [_signature, account, isAdmin, random]);

  useEffect(() => {
    if (account && _signature) {
      whoAmI();
    }
  }, [account, _signature]);

  useEffect(() => {
    if (account) {
      // getSignature();
      getBalanceFromContract();
    }
  }, [account, random]);

  const reloadStates = () => {
    setRandom(Math.random());
  };

  return (
    <>
      <Alert open={alertOpen} setOpen={setAlertOpen} />
      <ToastContainer style={{ fontSize: "0.9rem" }} />
      <CssBaseline />
      <MainHeader
        account={account}
        isAdmin={isAdmin}
        connectMetaMask={connectMetaMask}
        auth={getSignature}
        signature={_signature}
      />
      <Switch>
        {isAdmin && (
          <Route
            exact
            render={() => (
              <Admin
                orders={adminOrderIds}
                getAdminByOrderId={getAdminByOrderId}
                adminDeleteByOrderId={adminDeleteByOrderId}
                adminChangeStatusByOrderId={adminChangeStatusByOrderId}
                balance={getBalance}
                withdrawBalance={withdrawBalance}
                reloadStates={reloadStates}
                prices={prices}
                setPrices={setPrices}
                nonUSA={nonUSA}
                setNonUSA={setNonUSA}
                adminChangePrices={adminChangePrices}
                downloadCSV={downloadCSV}
              />
            )}
            path="/admin"
          />
        )}
        {_signature && (
          <Route
            exact
            render={() => (
              <Orders
                orders={orderIds}
                getByOrderId={fetchByOrderId}
                buy={buyVanilla}
              />
            )}
            path="/orders"
          />
        )}
        <Route
          exact
          render={() => (
            <Home
              loading={loading}
              reloadStates={reloadStates}
              sign={sign}
              signature={_signature}
              // address={address}
              // color={color}
              // setColor={setColor}
              image={image}
              setImage={setImage}
              quantity={quantity}
              setQuantity={setQuantity}
              // size={size}
              // setSize={setSize}
              // type={type}
              // setType={setType}
              signedOrder={signedOrder}
              dialog={dialog}
              setDialog={setDialog}
              buy={buy}
              country={country}
              state={state}
              city={city}
              setCountry={setCountry}
              setState={setState}
              setCity={setCity}
              setStreet={setStreet}
              street={street}
              setZipcode={setZipcode}
              zipcode={zipcode}
              orderInfo={orderInfo}
              setOrderInfo={setOrderInfo}
              prices={prices}
              nonUSA={nonUSA}
              shippingName={shippingName}
              setShippingName={setShippingName}
              removeBackground={removeBackground}
              setRemoveBackground={setRemoveBackground}
            />
          )}
          path="/"
        />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/how-it-works" component={Works} />
        <Redirect to="/" />
      </Switch>
      <MainFooter />
    </>
  );
}

export default App;
