import {
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  Input,
  Checkbox,
} from "@mui/material";
import Menu from "../components/menu/Menu";
import Resizer from "react-image-file-resizer";
import { useState } from "react";
import Dialog from "../components/dialog/Dialog";
import ButtonIndicator from "../components/buttonIndicator/ButtonIndicator";
import { GCTypesItems, GSTypesItem, GTypesItem } from "./constants";
import CountrySelector from "../components/selector/Selector";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import reactImageSize from "react-image-size";
import { useEffect } from "react";
import WithPagination from "../components/withPagination/WithPagination";
import { DisableElements } from "./Admin";

const Home = ({
  loading: loadingBuy,
  sign,
  image,
  setImage,
  quantity,
  setQuantity,
  country,
  state,
  city,
  setCountry,
  setState,
  setCity,
  setStreet,
  street,
  setZipcode,
  zipcode,
  signedOrder,
  dialog,
  setDialog,
  buy,
  reloadStates,
  orderInfo,
  setOrderInfo,
  prices,
  nonUSA,
  shippingName,
  setShippingName,
  removeBackground,
  setRemoveBackground,
  signature,
}) => {
  // const [] = useState("");
  // const [] = useState("Select");
  // const [] = useState("Select");
  // const [] = useState("1");
  // const [] = useState("");
  // const [] = useState("Select");

  // const [] = useState({
  //   size:"",
  //   type:"",
  //   color:"",
  //   quantity:"",
  //   address:""
  // });

  // const [country, setCountry] = useState("");
  // const [state, setState] = useState("");
  // const [city, setCity] = useState("");
  const [imgErr, setIMGErr] = useState(false);

  const [loading, setLoading] = useState(false);

  const handlerBuy = async (orderNumber) => {
    if (signedOrder) {
      await buy(orderNumber);
      reloadStates();
      handlerCloseDialog();
    }
  };

  const handlerState = ({ currentTarget: { value } }) => {
    setState(value);
  };

  const handlerCity = ({ currentTarget: { value } }) => {
    setCity(value);
  };
  const handlerStreet = ({ currentTarget: { value } }) => {
    setStreet(value);
  };

  const handlerShippingName = ({ currentTarget: { value } }) => {
    setShippingName(value);
  };

  const handlerZipcode = ({ currentTarget: { value } }) => {
    setZipcode(value);
  };

  const handlerQuantity = ({ currentTarget: { value } }) => {
    if (parseInt(value) > 0) setQuantity(value);
  };

  const handlerCloseDialog = () => {
    setDialog(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  // const uploadImage = async (image) => {
  //   const data = new FormData();
  //   data.append("file", image);
  //   data.append("upload_preset", "utmd03jk");
  //   data.append("cloud_name", "dtfhuqulk");

  //   return new Promise(async (resolve, reject) => {
  //     const res = await fetch(
  //       `https://api.cloudinary.com/v1_1/dtfhuqulk/image/upload`,
  //       {
  //         method: "POST",
  //         body: data,
  //       }
  //     );

  //     if (!res.ok) return reject(res.statusText);

  //     const resJSON = await res.json();

  //     resolve(resJSON.secure_url);
  //   });
  // };

  const fileSelected = async (e) => {
    const file = e.target.files[0];

    try {
      // let pixels = window.devicePixelRatio * 160 //dpi
      // let url = await uploadImage(file);
      setIMGErr(false);
      const { width, height } = await reactImageSize(URL.createObjectURL(file));
      // console.log(width, height);
      if (width < 400 || height < 400) return setIMGErr(true);
      setImage(file);
      // let base64 = await getBase64(file);
      // console.log("base64", base64);
      // Resizer.imageFileResizer(
      //   file, // the file from input
      //   480, // width
      //   480, // height
      //   "PNG", // compress format WEBP, JPEG, PNG
      //   100, // quality
      //   0, // rotation
      //   (uri) => {
      //     setImage(uri);
      //     // console.log(uri);
      //     // You upload logic goes here
      //   },
      //   "base64" // blob or base64 default base64
      // );
    } catch (e) {
      console.log(e);
    }
  };

  const handleSign = async () => {
    try {
      setLoading(true);
      await sign();
    } catch {}
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={dialog}
        handlerBuy={handlerBuy}
        data={{
          ...signedOrder,
          shippingFee: country?.value === "US" ? 0 : nonUSA,
        }}
        onClose={handlerCloseDialog}
      />
      <DisableElements disabled={!signature}>
        <Container style={{}} disableGutters maxWidth="md">
          <Grid container alignItems={"stretch"} spacing={3}>
            <Grid item xs sm md lg xl>
              <Box
                style={{
                  backgroundColor: "black",
                  padding: "20px",
                  borderRadius: 10,
                }}
              >
                <Pricing
                  items={[...GTypesItem, "Non USA Shipping Fee"]}
                  prices={[...prices, nonUSA]}
                  // handlerQuantity={handlerQuantity}
                  // quantity={quantity}
                  // orderInfo={orderInfo}
                  // setOrderInfo={setOrderInfo}
                />
              </Box>

              <Box mt={2} />
              <Box
                style={{
                  backgroundColor: "black",
                  padding: "20px",
                  borderRadius: 10,

                  width: "100%",
                  // height: "460px",
                }}
              >
                <Typography
                  style={{ fontWeight: 500, padding: "10px 0px" }}
                  color="white"
                >
                  Select NFT image <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <Box mt={1} />
            <Typography color="red">
            Please select minimum 150 dpi image.
            </Typography> */}
                <Box mt={1} />
                <Input
                  type="file"
                  inputProps={{
                    accept: "image/png, image/jpeg",
                  }}
                  onChange={fileSelected}
                />

                {imgErr && (
                  <Typography
                    color="red"
                    textAlign={"center"}
                    mt={1}
                    fontSize={"0.9rem"}
                  >
                    Minimum Image size 400x400 Pixels required.
                  </Typography>
                )}
                {image && (
                  <>
                    <Box mt={2} />
                    <Box
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 5,
                        padding: "4px 10px",
                      }}
                    >
                      <Typography fontWeight={"400"}>
                        Remove Background
                      </Typography>
                      <Checkbox
                        color="error"
                        checked={removeBackground}
                        onChange={(_, bool) => {
                          setRemoveBackground(bool);
                        }}
                      />
                    </Box>
                    <Box mt={1} />
                    <Box
                      style={{
                        width: "100%",
                        // height: "320px",
                        // display: "flex",
                        // alignItems: ,
                        // justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setImage("");
                        }}
                        style={{ position: "absolute", right: 0, top: 0 }}
                      >
                        <HighlightOffIcon color="error" />
                      </IconButton>
                      <img
                        // height="100%"
                        width="100%"
                        src={URL.createObjectURL(image)}
                        alt="image"
                      />
                    </Box>
                  </>
                )}
              </Box>

              <Box mt={2} />

              <Box
                style={{
                  backgroundColor: "black",
                  padding: "20px",
                  borderRadius: 10,
                }}
              >
                <SelectFeatures
                  handlerQuantity={handlerQuantity}
                  quantity={quantity}
                  orderInfo={orderInfo}
                  setOrderInfo={setOrderInfo}
                />
              </Box>

              <Box mt={2} />
            </Grid>
            <Grid item xs sm md lg xl>
              <Box
                style={{
                  backgroundColor: "black",
                  padding: "20px",
                  borderRadius: 10,
                }}
              >
                <AddressSection
                  handlerCity={handlerCity}
                  handlerState={handlerState}
                  handlerStreet={handlerStreet}
                  handlerZipcode={handlerZipcode}
                  handlerShippingName={handlerShippingName}
                  shippingName={shippingName}
                  country={country}
                  setCountry={setCountry}
                  zipcode={zipcode}
                  street={street}
                  city={city}
                  state={state}
                />

                <Box mt={4} />
                <ButtonIndicator
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleSign}
                  disabled={loading || loadingBuy}
                  type="over"
                >
                  Order Now
                </ButtonIndicator>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DisableElements>
    </>
  );
};

export default Home;

const Pricing = ({ items = [...GTypesItem], prices = [0, 0, 0, 0] }) => {
  return (
    <>
      <Typography fontSize={"1rem"} fontWeight={"bold"} color="white">
        Pricing
      </Typography>
      {items.map((item, idx) => {
        return (
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"5px 0px"}
            borderBottom={
              idx !== items.length - 1 ? "1px solid white" : undefined
            }
          >
            <Grid item>
              <Typography color="white">{item}</Typography>
            </Grid>
            <Grid item>
              <Typography color="white">{prices[idx]}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const SelectFeatures = ({
  handlerQuantity = () => {},
  quantity = "",
  orderInfo,
  setOrderInfo,
}) => {
  const [page, setPage] = useState(0);

  const init = (value) => {
    let info = {};
    let iter = [...Array(value).keys()];

    let len = Object.keys(orderInfo).length;

    let original = { ...orderInfo };

    iter.forEach((v, i) => {
      if (i < len) {
        info[v] = {
          size: original[i].size,
          color: original[i].color,
          type: original[i].type,
        };
      } else {
        info[v] = {
          size: "Select",
          color: "Select",
          type: "Select",
        };
      }
    });

    setOrderInfo(info);
  };

  const handlerInfo = (e) => {
    const {
      currentTarget: { value },
    } = e;
    if (parseInt(value) > 0) {
      init(parseInt(value));

      handlerQuantity(e);
    }
  };

  const handlerOrderInfo = (idx, attr) => (value) => {
    let info = { ...orderInfo };
    info[idx][attr] = value;
    setOrderInfo(info);
  };

  useEffect(() => {
    init(parseInt(quantity.toString()));
    if (page + 1 > parseInt(quantity.toString())) {
      setPage(parseInt(quantity.toString()) - 1);
    }
  }, [quantity]);

  // useEffect(() => {
  //   if (parseInt(quantity.toString()) > page + 1) {
  //     setPage(parseInt(quantity.toString()));
  //   }
  // }, [quantity]);

  return (
    <>
      <TitledMenu
        title={"Select Quantity"}
        type="number"
        fn={handlerInfo}
        value={quantity}
      />

      <WithPagination
        rowsPerPage={1}
        func={({ size, type, color }, idx) => (
          <>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs sm md lg xl>
                <TitledMenu
                  title={"Garment Sizes"}
                  items={GSTypesItem}
                  fn={handlerOrderInfo(page, "size")}
                  value={size}
                />
              </Grid>
              <Grid item xs sm md lg xl>
                <TitledMenu
                  title={"Garment Types"}
                  items={GTypesItem}
                  fn={handlerOrderInfo(page, "type")}
                  value={type}
                />
              </Grid>
            </Grid>
            <Box mt={2} />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TitledMenu
                  title={"Garment Colors"}
                  items={GCTypesItems}
                  fn={handlerOrderInfo(page, "color")}
                  value={color}
                />
              </Grid>
            </Grid>
          </>
        )}
        page={page}
        setPage={setPage}
        // items={[
        //   {
        //     size: "Select",
        //     color: "Select",
        //     type: "Select",
        //   },
        // ]}
        items={Object.keys(orderInfo).map((v, i) => ({
          size: orderInfo[v].size,
          color: orderInfo[v].color,
          type: orderInfo[v].type,
        }))}
      />
    </>
  );
};

const AddressSection = ({
  country,
  setCountry,
  state,
  handlerState,
  handlerCity,
  city,
  handlerStreet,
  street,
  handlerZipcode,
  zipcode,
  handlerShippingName,
  shippingName,
}) => {
  return (
    <>
      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        Shipping Name <span style={{ color: "red" }}>*</span>
      </Typography>
      <Input
        fullWidth
        color="secondary"
        disableUnderline
        style={{
          color: "white",
          border: "1px solid rgba(255,255,255,0.6)",
          borderRadius: 5,
          padding: "0px 10px",
          minHeight: 36,
        }}
        onChange={handlerShippingName}
        value={shippingName}
      />

      <Box mt={1} />

      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        Street Address <span style={{ color: "red" }}>*</span>
      </Typography>
      <Input
        fullWidth
        color="secondary"
        disableUnderline
        style={{
          color: "white",
          border: "1px solid rgba(255,255,255,0.6)",
          borderRadius: 5,
          padding: "0px 10px",
          minHeight: 36,
        }}
        onChange={handlerStreet}
        value={street}
      />
      <Box mt={1} />

      {/* <Box mt={2} /> */}
      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        State <span style={{ color: "red" }}>*</span>
      </Typography>
      <Input
        fullWidth
        color="secondary"
        disableUnderline
        style={{
          color: "white",
          border: "1px solid rgba(255,255,255,0.6)",
          borderRadius: 5,
          padding: "0px 10px",
          minHeight: 36,
        }}
        onChange={handlerState}
        value={state}
      />

      <Box mt={1} />
      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        City <span style={{ color: "red" }}>*</span>
      </Typography>
      <Input
        fullWidth
        color="secondary"
        disableUnderline
        style={{
          color: "white",
          border: "1px solid rgba(255,255,255,0.6)",
          borderRadius: 5,
          padding: "0px 10px",
          minHeight: 36,
        }}
        onChange={handlerCity}
        value={city}
      />

      <Box mt={1} />

      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        Zip Code <span style={{ color: "red" }}>*</span>
      </Typography>
      <Input
        fullWidth
        color="secondary"
        disableUnderline
        style={{
          color: "white",
          border: "1px solid rgba(255,255,255,0.6)",
          borderRadius: 5,
          padding: "0px 10px",
          minHeight: 36,
        }}
        onChange={handlerZipcode}
        value={zipcode}
      />
      <Box mt={1} />

      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        Country <span style={{ color: "red" }}>*</span>
      </Typography>

      <CountrySelector value={country} setValue={setCountry} />
    </>
  );
};

const TitledMenu = ({
  title = "Select Size",
  items,
  type = "menu" | "number",
  fn,
  value,
}) => {
  return (
    <>
      <Typography
        style={{ fontWeight: 500, padding: "10px 0px" }}
        color="white"
      >
        {title} <span style={{ color: "red" }}>*</span>
      </Typography>
      {type == "number" ? (
        <Input
          fullWidth
          style={{
            color: "white",
            border: "1px solid rgba(255,255,255,0.6)",
            borderRadius: 5,
            padding: "0px 10px",
            minHeight: 36,
          }}
          onChange={fn}
          value={value}
          type="number"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      ) : (
        <Menu fn={fn} value={value} items={items} />
      )}
    </>
  );
};
